<template>
  <component :is="layout" />
  <div id="overlay">
    <div class="cv-spinner">
      <span class="spinner"></span>
      <img class="spinner-img" src="/images/loading.gif">
    </div>
  </div>
</template>

<script>
import WebLayout from "./layouts/WebLayout.vue";
import PanelLayout from "./layouts/PanelLayout";

export default {
  components: {
    WebLayout,
    PanelLayout,
  },
  data() {
    return {
      layout: null,
    };
  },
  watch: {
    $route(to) {
      // set layout by route meta
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout;
        document.getElementById("page-top").classList.add("bg-gradient-primary");
      } else {
        this.layout = "PanelLayout"; // this is default layout if route meta is not set
        document.getElementById("page-top").classList.remove("bg-gradient-primary");
      }
    },
  },
  mounted() {
    
    setTimeout(() => {
      if (document.getElementsByClassName("modal-open").length > 0) {
        document.getElementsByClassName("modal-open")[0].remove();
      }
      if (localStorage.getItem("contentBackground")) {
        if (document.getElementById("content-wrapper")) {
          document.getElementById("content-wrapper").style.backgroundImage = 'url(' + localStorage.getItem("contentBackground") + ')';
        }
        if (document.getElementById("bgBlur")) {
          document.getElementById("bgBlur").style.backgroundImage = 'url(' + localStorage.getItem("contentBackground") + ')';


        }
      }
    }, 500);
  }
};
</script>