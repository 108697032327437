<template>

    <nav class="navbar navbar-expand navbar-light topbar static-top shadow">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click="sideNavToggle">
            <i class="fa fa-bars"></i>
        </button>

        <!-- Topbar Search -->
        <!-- <form class="d-none d-sm-inline-block form-inline ml-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
            <div class="input-group">
                <input type="text" class="form-control bg-light border-0 small" placeholder="جستجو ..."
                    aria-label="Search" aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                    </button>
                </div>
            </div>
        </form> -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-block d-none rounded-circle mr-3" @click="sideNavToggle">
            <i class="fa-solid fa-bars-staggered"></i>
        </button>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav mr-auto">

            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none">
                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-search fa-fw"></i>
                </a>
                <!-- Dropdown - Messages -->
                <div class="dropdown-menu dropdown-menu-left p-3 shadow animated--grow-in"
                    aria-labelledby="searchDropdown">
                    <form class="form-inline mr-auto w-100 navbar-search">
                        <div class="input-group">
                            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-primary" type="button">
                                    <i class="fa-solid fa-magnifying-glass fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </li>





            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="ml-2 d-none d-lg-inline text-gray-600 small">{{ userName }}</span>
                    <img class="img-profile rounded-circle" src="/images/avator.jpg">
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="userDropdown">

                    <button class="dropdown-item  border-none" @click="openChangeBackground">

                        <i class="fa-solid fa-gears fa-fw mr-2 text-gray-400"></i>
                        تصویر زمینه
                    </button>


                </div>
            </li>

        </ul>

    </nav>

</template>
<script>
// import axios from "axios";
// import { Loading } from "../../public/js/Utilities"
import ChangeBackground from '../../components/panel/ChangeBackground.vue';
import { openModal } from "jenesius-vue-modal";
export default {

    data() {

        return {
            mobile: window.innerWidth <= 767,
            userName: ""
        }
    },
    mounted() {
        this.userName = localStorage.getItem("userName");
        if (this.mobile == true) {
            document.getElementById("page-top").classList.add("sidebar-toggled");
            if (document.getElementById("accordionSidebar")) {
                document.getElementById("accordionSidebar").classList.add("toggled");
            }
        }
    },
    methods: {
        sideNavToggle() {

            if (document.getElementById("page-top").classList.contains("sidebar-toggled")) {
                document.getElementById("page-top").classList.remove("sidebar-toggled");
            }
            else {
                document.getElementById("page-top").classList.add("sidebar-toggled");
            }
            if (document.getElementById("accordionSidebar").classList.contains("toggled")) {
                document.getElementById("accordionSidebar").classList.remove("toggled");
            }
            else {
                document.getElementById("accordionSidebar").classList.add("toggled");
            }
        },
        openChangeBackground() {
            openModal(ChangeBackground)
        }
    }

}
</script>
<style>
/* Don't forget to include the animation time for the start block */
.fade-enter-active,
.fade-leave-active,
.fade-enter-active .modal-item,
.fade-leave-active .modal-item {
    transition: 1.0s;
}

.fade-enter-from .modal-item,
.fade-leave-to .modal-item {
    transform: scale(0);
}

.fade-enter-from,
.fade-leave-to {
    background-color: transparent;
}
</style>