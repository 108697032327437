<template>
    
    <ul class="navbar-nav sidebar sidebar-dark accordion toggled" :style="{backgroundColor:manifest.theme_color}" id="accordionSidebar"
        @mouseover="sideNavFull" @mouseout="sideNavSmall">

        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/">
            <div class="sidebar-brand-icon">
                <img :src="manifest.icons[0].src" width="30">
            </div>
            <div class="sidebar-brand-text mx-3">سامانه نظرسنجی</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item active">
            <router-link to="/dashboard" class="nav-link">

                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>داشبورد</span>
            </router-link>
        </li>
        <!-- Divider -->
        <hr class="sidebar-divider" v-if="Access('SysAdmin')">


        <li class="nav-item active" v-if="Access('SysAdmin')">
            <router-link to="/role-list" class="nav-link">
                <i class="fas fa-project-diagram"></i>
                <span> گروه های دسترسی</span>
            </router-link>
        </li>

        <hr class="sidebar-divider" v-if="Access('UserManager,SysAdmin')">


        <li class="nav-item active" v-if="Access('UserManager,SysAdmin')">
            <router-link to="/user-list" class="nav-link">
                <i class="fa-solid fa-users"></i>
                <span> کاربران</span>
            </router-link>
        </li>

        <hr class="sidebar-divider" v-if="Access('SurvayManager,SysAdmin')">

        <li class="nav-item active" v-if="Access('SurvayManager,SysAdmin')">
            <router-link to="/questioncategory-list" class="nav-link">
                <i class="fa-solid fa-boxes"></i>
                <span> دسته بندی سوالات</span>
            </router-link>
        </li>

        <hr class="sidebar-divider" v-if="Access('SurvayManager,SysAdmin')">

        <li class="nav-item active" v-if="Access('SurvayManager,SysAdmin')">
            <router-link to="/survay-list" class="nav-link">
                <i class="fa-solid fa-list-ul"></i>
                <span> نظرسنجی</span>
            </router-link>
        </li>
        <hr class="sidebar-divider" v-if="Access('SurvayResultManager,SysAdmin')">
        <li class="nav-item active" v-if="Access('SurvayResultManager,SysAdmin')">
            <router-link to="/survayresult-list" class="nav-link">
                <i class="fa-solid fa-list-check"></i>
                <span> نتایج</span>
            </router-link>
        </li>
        <hr class="sidebar-divider" v-if="Access('GiftManager,SysAdmin')">
        <li class="nav-item active" v-if="Access('GiftManager,SysAdmin')">
            <router-link to="/gift-list" class="nav-link">
                <i class="fa-solid fa-gift"></i>
                <span> هدایا و جوایز</span>
            </router-link>
        </li>
        <hr class="sidebar-divider" v-if="Access('Accountancy,SysAdmin')">
        <li class="nav-item active" v-if="Access('Accountancy,SysAdmin')">
            <router-link to="/wallet-list" class="nav-link">
                <i class="fa-solid fa-coins"></i>
                <span> لیست تراکنش ها</span>
            </router-link>
        </li>
        <hr class="sidebar-divider" v-if="Access('Accountancy,SysAdmin')">
        <li class="nav-item active" v-if="Access('Accountancy,SysAdmin')">
            <router-link to="/user-gift-list" class="nav-link">
                <i class="fa-solid fa-gifts"></i>
                <span> لیست هدایای کاربران</span>
            </router-link>
        </li>
        <hr class="sidebar-divider" v-if="Access('SurvayResultManager,SysAdmin')">
        <li class="nav-item active" v-if="Access('SurvayResultManager,SysAdmin')">
            <router-link to="/aiResponse-list" class="nav-link">
                <i class="fa-solid fa-robot"></i>
                <span> تحلیل هوش مصنوعی</span>
            </router-link>
        </li>
    </ul>
    <widget-container-modal class="modal-open" />
</template>
<script>
import { RoleAccess, ShowAlert } from "../../../public/js/Utilities"
import { container, openModal } from "jenesius-vue-modal";
import SurvayResultCompare from "../../pages/panel/survayResult/SurvayResultCompare.vue"
export default {
    components: { WidgetContainerModal: container },
    data() {
        return {
            deviceSize: window.innerWidth > 767,
        }
    },

    methods: {
        Access(RoleNames) {
            return RoleAccess(RoleNames);
        },
        Update() {
            ShowAlert("success", "در حال توسعه میباشد", "__________________________")
        },
        sideNavFull() {
            if (this.deviceSize == true) {
                if (document.getElementById("accordionSidebar").classList.contains("toggled")) {
                    document.getElementById("accordionSidebar").classList.add("full")
       
                }
            }

        },


        sideNavSmall() {
            if (this.deviceSize == true) {
                if (document.getElementById("accordionSidebar").classList.contains("toggled")) {

                    document.getElementById("accordionSidebar").classList.remove("full")
                }
            }
        },
        openCompareModal() {
            openModal(SurvayResultCompare);
        }

    },


}
</script>

<style>
.sidebar.full {
    width: 14rem !important;
}

.sidebar.full .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: .8rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
}

.sidebar.full .nav-item {
    position: relative;
}

@media (min-width: 768px) {
    .sidebar.full .nav-item .nav-link {
        display: block;
        width: 100%;
        text-align: right;
        padding: .5rem;
        width: 14rem;
    }

    .sidebar.full .nav-item .nav-link span {
        font-size: 0.7rem;
        display: inline;
    }

    .sidebar.full .sidebar-brand .sidebar-brand-text {
        display: inline;
    }
}
</style>