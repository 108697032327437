<template>
    <div class="modal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title p-2">
                        <i class="fa fa-check text-success m-3"></i>مقایسه نظرات کاربران
                    </h5>
                    <button @click="closeModal()" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-3">
                    <div class="result-compare d-flex py-3">
                        <div class="col-md-6" v-for="compare in compareUser" :key="compare">
                            <div class="card">
                                <div class="survey-participant-detail">
                                    <div class="alert alert-primary d-flex justify-content-between" role="alert">
                                        <div class="ml-2"><span>عنوان: </span><span>{{ compare.survayName }}</span>
                                        </div>
                                        <div class="ml-2"><span>شرکت کننده: </span><span>{{ compare.userFullName
                                                }}</span>
                                        </div>
                                        <div class="ml-2"><span>کد ملی: </span><span>{{ compare.userName }}</span>
                                        </div>



                                    </div>
                                </div>
                                <div class="survey-questions pb-5">
                                    <div class="row" v-for="Question in compare.questions" :key="Question">
                                        
                                        <div class="col-md-12">
                                            <div class="survey-question py-3" :style="{
                                        borderRight: '3px solid ' + Question.survayQuestionCategoryColor, marginBottom: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId).length - 1].id) ? '10px' : '0',
                                        borderBottomRightRadius: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId).length - 1].id) ? '10px' : '0',
                                        borderBottomLeftRadius: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId).length - 1].id) ? '10px' : '0',
                                        borderTopRightRadius: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[0].id) ? '10px' : '0',
                                        borderTopLeftRadius: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[0].id) ? '10px' : '0',
                                        borderBottom: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId).length - 1].id) ? '1px solid #eee' : '0',
                                        borderTop: (Question.id == compare.questions.filter(s => s.survayQuestionCategoryId == Question.survayQuestionCategoryId)[0].id) ? '1px solid #eee' : '0',
                                    }" >
                                                <div class="question py-3">
                                                    <div v-if="Question.questionType == 1"
                                                        class="survey-question-title">
                                                        <span>{{ Question.questionText }}</span>
                                                    </div>
                                                    <div v-if="Question.questionType == 2"
                                                        class="survey-question-title d-flex justify-content-center">
                                                        <div class="survey-question-image">
                                                            <img :src="Question.questionImgUrl" alt="">
                                                        </div>

                                                    </div>
                                                    <div v-if="Question.questionType == 3"
                                                        class="survey-question-title">
                                                        <span class="mb-3">{{ Question.questionText }}</span>
                                                        <div class="d-flex justify-content-center">
                                                            <div class="survey-question-image">
                                                                <img :src="Question.questionImgUrl" alt="">
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="answer py-3">
                                                    <div v-if="Question.questionAnswerType == 1">
                                                        <textarea :id="'QuestionTextAnswer-' + Question.id"
                                                            v-model="Question.answer" class="form-control" readonly
                                                            rows="3"></textarea>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 2">
                                                        <div v-for="item in Question.questionAnswers"
                                                            class="custom-control custom-radio custom-control-inline "
                                                            :key="item">
                                                            <input type="radio" :name="Question.id + '-' + compare.id"
                                                                :checked="CheckedOptionQuestion(item.id, Question.answer)"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label question-answer-label">
                                                                <div class="custom-radio-img">
                                                                    <img :src="item.text" alt="">
                                                                </div>
                                                                <span class="text-info">{{ item.allUserAnswer }} پاسخ
                                                                    دهی</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 3">
                                                        <div v-for="item in Question.questionAnswers"
                                                            class="custom-control custom-radio custom-control-inline"
                                                            :key="item">
                                                            <input type="radio" :name="Question.id + '-' + compare.id"
                                                                :checked="CheckedOptionQuestion(item.id, Question.answer)"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label">{{ item.text
                                                                }}
                                                                <div>
                                                                    <span class="text-info">{{ item.allUserAnswer }}
                                                                        پاسخ
                                                                        دهی</span>
                                                                </div>
                                                            </label>

                                                        </div>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 4" class="d-flex">
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-star icon"
                                                                :class="[CastRateAnswerForRate(Question.answer) == '1' || CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 1)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-star icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 2)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-star icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 3)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-star icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 4)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-star icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && (CastRateAnswerForRate(Question.answer) != '4') && CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 5)
                                                                }}</small>
                                                        </div>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 5" class="d-flex">
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-thumbs-up icon"
                                                                :class="[CastRateAnswerForRate(Question.answer) == '1' || CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 1)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-thumbs-up icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 2)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-thumbs-up icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 3)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-thumbs-up icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 4)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-thumbs-up icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && (CastRateAnswerForRate(Question.answer) != '4') && CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 5)
                                                                }}</small>
                                                        </div>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 6" class="d-flex">
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-heart icon"
                                                                :class="[CastRateAnswerForRate(Question.answer) == '1' || CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 1)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-heart icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && CastRateAnswerForRate(Question.answer) == '2' || CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 2)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-heart icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && CastRateAnswerForRate(Question.answer) == '3' || CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 3)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-heart icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && CastRateAnswerForRate(Question.answer) == '4' || CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 4)
                                                                }}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column algin-items-center justify-content-center text-center">
                                                            <span class="fa-heart icon"
                                                                :class="[(CastRateAnswerForRate(Question.answer) != '1') && (CastRateAnswerForRate(Question.answer) != '2') && (CastRateAnswerForRate(Question.answer) != '3') && (CastRateAnswerForRate(Question.answer) != '4') && CastRateAnswerForRate(Question.answer) == '5' ? 'fa-solid' : 'fa-regular']"></span>
                                                            <small>{{ CastRateAnswerForAllUser(Question.answer, 5)
                                                                }}</small>
                                                        </div>

                                                    </div>
                                                    <input class="RateResultInput" type="hidden"
                                                        :id="'RateResultInput-' + Question.id" />
                                                    <div :id="'RateResultDiv-' + Question.id" class="text-success mt-2">

                                                    </div>

                                                    <div v-if="Question.questionAnswerType == 7">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <a :href="Question.answer" download
                                                                    class="btn btn-info">
                                                                    <i class="fa fa-download"></i>
                                                                    دانلود فایل اپلود شده</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="Question.questionAnswerType == 8">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <input type="color" class="form-control"
                                                                    :value="Question.answer" disabled
                                                                    :id="'ColorInput-' + Question.id">
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-12"
                                            v-if="compare.survaySuggestion != '' && compare.survaySuggestion != null">
                                            <div class="survey-question-title text-center"><span> نظر یا پیشنهاد</span>
                                            </div>
                                            <textarea class="form-control" readonly v-model="compare.survaySuggestion"
                                                rows="3"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-secondary btn-sm" @click="closeModal()">بستن</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { closeModal } from "jenesius-vue-modal"
export default {
    methods: {
        closeModal() {
            closeModal();
        },
        CastRateAnswerForAllUser(text, rate) {
            return String(String(String(text).split(",")[1]).split("&").filter(s => s.startsWith(rate))[0]).split(":")[1];
        },
        CastRateAnswerForRate(text) {
            return String(String(text).split(",")[0]);
        },
        CheckedOptionQuestion(id, answer) {
            return id == answer;
        }
    },
    data() {
        return {
            compareUser: []
        }
    },
    props: {
        users: []
    },
    mounted() {
        this.compareUser = this.users;
    },

}
</script>
<style>
.result-compare {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media(min-width:768px) {
    .survay-question-category {
        text-align: left;
    }
}
</style>