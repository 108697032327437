import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './axios'
import manifestJson from "../public/manifest/manifest.json"
import { config } from "jenesius-vue-modal";
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
config({
    animation: "fade", // Any name
    
})

let app = createApp(App);
app.config.globalProperties = {
    manifest: manifestJson,
}
app.use(router);
app.component('DatePicker', Vue3PersianDatetimePicker)
app.component('QuillEditor', QuillEditor)
app.mount('#app');
// line 2: Register layouts
