<template>
    <!-- Page Wrapper -->
    <div id="bgBlur"></div>
    <div id="wrapper">

        <!-- Sidebar -->
        <aside>
            <navbar-component></navbar-component>
        </aside>
        <!-- End of Sidebar -->


        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Header -->
            <header>
                <HeaderComponent />
            </header>

            <!-- End of Header -->
            <!-- Main Content -->
            <main>
                <div id="content" class="py-4">



                    <!-- Begin Page Content -->

                    <div class="container-fluid">

                        <router-view />

                    </div>


                    <!-- /.container-fluid -->

                </div>
            </main>
            <!-- End of Main Content -->
            <!-- Footer -->
             <footer class="sticky-footer">
                <FooterComponent />
             </footer>
           
            <!-- End of Footer -->


        </div>
        <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->
</template>

<script>
import NavbarComponent from "../components/panel/NavbarComponent.vue"
import FooterComponent from "../components/panel/FooterComponent.vue"
import HeaderComponent from "../components/panel/HeaderComponent.vue"
export default {
    components: {
        NavbarComponent,
        FooterComponent,
        HeaderComponent
    },
    mounted() {
        // this is dashboard layout
    }
}
</script>