import axios from "axios";
import { Loading, ShowAlert } from "../public/js/Utilities"
//•Production
// axios.defaults.baseURL = 'https://localhost:7084/api/'
//•Test
  axios.defaults.baseURL = 'https://service.parapal.ir/api'

axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('token');

axios.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error

    if (error.message.includes("Network")) {
        Loading(0);
        ShowAlert("danger", "مشکلی در ارتباط وجود دارد", "");
    } else {
        Loading(0);
        ShowAlert("danger", error.message, "");
    }

});

// axios.interceptors.response.use((response) => {
//     if (response != undefined) {
//         if (!response.data.isSuccess) {
//             ShowAlert("danger", response.data.message, "");
//         return;
//         }
//         return;
//     }
// });