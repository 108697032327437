import { createRouter, createWebHistory } from 'vue-router'
import WebLayout from './layouts/WebLayout.vue'
const routes = [
    {
        path: '/',
        name: 'صفحه اصلی',
        component: () => import('./pages/web/home/HomePage.vue'),
        meta: {
            requiresAuth: true,
            layout: WebLayout
        },
    },
    {
        path: '/dashboard',
        name: 'داشبورد',
        component: () => import('./pages/panel/DashboardPage.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/role-list',
        name: 'لیست گروه های دسته بندی',
        component: () => import('./pages/panel/role/RoleList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/user-list',
        name: 'لیست کاربران',
        component: () => import('./pages/panel/user/UserList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/survay-list',
        name: 'لیست نظرسنجی',
        component: () => import('./pages/panel/survay/SurvayList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/questioncategory-list',
        name: 'دسته بندی سوالات',
        component: () => import('./pages/panel/questionCategory/QuestionCategoryList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/survay-create',
        name: 'افزودن',
        component: () => import('./pages/panel/survay/SurvayCreate.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/survay-edit=:SurvayId',
        name: 'ویرایش',
        component: () => import('./pages/panel/survay/SurvayEdit.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/SignInByEncryptUserName=:encryptUserName',
        name: 'ورود',
        component: () => import('./pages/account/SignInByEncryptUserName.vue'),
        meta: {
            layout: WebLayout
        },
    },
    {
        path: '/survayresult-list',
        name: 'نتایج',
        component: () => import('./pages/panel/survayResult/SurvayResultList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/SignIn',
        name: 'ورود و ثبت نام',
        component: () => import('./pages/web/account/SignIn.vue'),
        meta: {
            layout: WebLayout
        },
    },
    {
        path: '/ErorrAuth',
        name: 'خطا',
        component: () => import('./pages/ErorrAuth.vue'),
        meta: {
            layout: WebLayout,
        },
    },
    {
        path: '/survay-participant=:SurvayId',
        name: 'شرکت در نظر سنجی',
        component: () => import('./pages/web/survay/SurvayParticipant.vue'),
        meta: {
            layout: WebLayout,
            requiresAuth: true
        },
    },
    {
        path: '/signup',
        name: 'خروج',
        component: () => import('./pages/account/SetUserFullName.vue'),
        meta: {
            layout: WebLayout,
            requiresAuth: true
        },
    },
    {
        path: '/gift-list',
        name: 'لیست هدایا',
        component: () => import('./pages/panel/gift/GiftList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/wallet-list',
        name: 'لیست تراکنش ها',
        component: () => import('./pages/panel/wallet/WalletList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/user-gift-list',
        name: 'لیست هدایای کاربران',
        component: () => import('./pages/panel/userGift/UserGiftList.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/aiResponse-list',
        name: 'تحلیل هوش مصنوعی',
        component: () => import('./pages/panel/aiResponse/AiResponseList.vue'),
        meta: {
            requiresAuth: true
        },
    },



]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,

})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") != null) {
            next()
            return
        }
        next('/ErorrAuth')
    } else {
        next()
    }
})
export default router;