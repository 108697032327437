<template>
    <div class="modal">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title"><span class="ml-1 text-primary"><i class="far fa-image"></i></span>تغییر
                        تصویر زمینه</h6>
                    <button @click="closeModal()" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-3 mb-3">
                            <div class="bg-image  bg-none" @click="changImage('')">
                                <span class="h-100 d-flex justify-content-center align-items-center">بدون تصویر</span>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="bg-image" @click="changImage('/images/background/bg1.jpg')">
                                <img src="images/background/bg1.jpg" class="w-100" alt="">
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="bg-image" @click="changImage('/images/background/bg2.jpg')">
                                <img src="images/background/bg2.jpg" class="w-100" alt="">
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="bg-image" @click="changImage('/images/background/bg3.jpg')">
                                <img src="images/background/bg3.jpg" class="w-100" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="bg-image" @click="changImage('/images/background/bg4.jpg')">
                                <img src="images/background/bg4.jpg" class="w-100" alt="">
                            </div>
                        </div>

                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-primary" @click="closeModal()">ذخیره</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="closeModal()">بستن</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
// import axios from "axios";
import { closeModal } from "jenesius-vue-modal"
export default {
    methods: {
        closeModal() {
            closeModal()
        },
        changImage(url) {
            localStorage.setItem("contentBackground", url);
            document.getElementById("content-wrapper").style.backgroundImage = 'url(' + url + ')';
            document.getElementById("bgBlur").style.backgroundImage = 'url(' + url + ')';
           
        },

    }
}
</script>

<style>
.bg-image {
    height: 100px;
    overflow: hidden;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.bg-none {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.bg-none span {
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.bg-image img {
    height: 88px;

    border-radius: 5px;
}
</style>
